import { MdMoreHoriz, MdOpenInNew } from 'react-icons/md';
import { Menu, MenuItem } from '@material-ui/core';
import { toast } from 'react-toastify';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import dayjs from 'dayjs';
import DatabaseContext from '../../contexts/DatabaseContext';
import Markdown from '../shared/Markdown';
import ModalContext from '../../contexts/ModalContext';
import PopConfirmModal from '../../modals/PopConfirmModal';
import * as styles from './ResumePreview.module.css';

const menuToggleDataTestIdPrefix = 'resume-preview-menu-toggle-';

const ResumePreview = ({
  resume,
  showDuplicateMenu = resume.public,
  showRenameMenu = true,
  showArchiveMenu = true,
  showReadOnlyShowHideMenu = resume.public,
  showDeleteMenu = true,
  styleType = 'user',
}) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { emitter, events } = useContext(ModalContext);
  const { duplicateResume, deleteResume, updateResume } =
    useContext(DatabaseContext);

  const handleOpen = () => {
    window.open(`/app/builder/${resume.id}`, '_blank');
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDuplicate = () => {
    duplicateResume(resume);
    setAnchorEl(null);
  };

  const handleRename = () => {
    emitter.emit(events.CREATE_RESUME_MODAL, resume);
    setAnchorEl(null);
  };

  const handleReadOnlyShowHide = () => {
    resume.show2readonly = !resume.show2readonly;
    updateResume(resume);
    setAnchorEl(null);
  };

  const handleArchive = () => {
    resume.public = !resume.public;
    if (!resume.public && resume.show2readonly) {
      resume.show2readonly = false;
    }
    updateResume(resume);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    deleteResume(resume.id);
    toast(t('dashboard.toasts.deleted', { name: resume.name }));
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.resume}>
      <div
        className={cx(
          styleType === 'user' ? styles.backdrop : styles.backdropAdmin,
        )}
      >
        <img src={resume.profile?.photograph} alt={resume.name} />
      </div>
      <div
        className={cx(styleType === 'user' ? styles.page : styles.pageAdmin)}
      >
        <MdOpenInNew
          color="#fff"
          size="48"
          className="cursor-pointer"
          onClick={handleOpen}
        />

        {(showDeleteMenu ||
          showDuplicateMenu ||
          showRenameMenu ||
          showArchiveMenu ||
          showReadOnlyShowHideMenu) && (
          <MdMoreHoriz
            data-testid={`${menuToggleDataTestIdPrefix}${resume.id}`}
            color="#fff"
            size="48"
            className="cursor-pointer"
            aria-haspopup="true"
            onClick={handleMenuClick}
          />
        )}

        <Menu
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {showArchiveMenu && (
            <MenuItem onClick={handleArchive}>
              {resume.public ? 'Archive' : 'Unarchive'}
            </MenuItem>
          )}
          {showRenameMenu && <MenuItem onClick={handleRename}>Rename</MenuItem>}
          {showReadOnlyShowHideMenu && (
            <MenuItem onClick={handleReadOnlyShowHide}>
              {resume.show2readonly ? 'Hide' : 'Show'}
            </MenuItem>
          )}
          {showDuplicateMenu && (
            <MenuItem onClick={handleDuplicate}>
              {t('dashboard.buttons.duplicate')}
            </MenuItem>
          )}
          {showDeleteMenu && (
            <MenuItem>
              <span className="text-red-600 font-medium">
                <PopConfirmModal title="Are you sure?" onConfirm={handleDelete}>
                  {t('shared.buttons.delete')}
                </PopConfirmModal>
              </span>
            </MenuItem>
          )}
        </Menu>
      </div>
      <div
        className={cx(styleType === 'user' ? styles.meta : styles.metaAdmin)}
      >
        <div>{resume.name}</div>
        {resume.comments && (
          <div className={styles.tooltip}>
            <IoIosInformationCircleOutline className="text-3xl mx-2" />
            <span className={styles.tooltiptext}>
              <Markdown className="markdown text-sm">
                {resume.comments.body}
              </Markdown>
            </span>
          </div>
        )}

        {resume.updatedAt && (
          <span>
            {t('dashboard.lastUpdated', {
              timestamp: dayjs(resume.updatedAt)
                .locale(i18n.language.substr(0, 2))
                .fromNow(),
            })}
          </span>
        )}

        {resume.createdAt && (
          <span>
            {t('dashboard.createdAt', {
              timestamp: dayjs(resume.createdAt)
                .locale(i18n.language.substr(0, 2))
                .fromNow(),
            })}
          </span>
        )}
      </div>
      <p>{resume.show2readonly ? '🟢' : '🔴'}</p>
    </div>
  );
};

export default ResumePreview;

export { menuToggleDataTestIdPrefix };
